/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

@font-face {
  font-family: "jf-flat-regular";
  src: url("../fonts/jf-flat-regular/jf-flat-regular.eot");
  src: local("â˜º"),
    url("../fonts/jf-flat-regular/jf-flat-regular.woff") format("woff"),
    url("../fonts/jf-flat-regular/jf-flat-regular.ttf") format("truetype"),
    url("../fonts/jf-flat-regular/jf-flat-regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

div[dir="rtl"],
div[dir="rtl"] * {
  font-family: "jf-flat-regular" !important;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --545454: #545454;
  --0f0f0f: #0f0f0f;
  --primary: #465b95;
  --shadow: 0px 0px 24px rgba(15, 106, 241, 0.1);
  --e6e7ee: #e6e7ee;
  --a9a9a9: #a9a9a9;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: var(--e6e7ee);
  border-radius: 100px;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-family: "Montserrat", sans-serif !important;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

/* font-family: 'Montserrat', sans-serif; */
body {
  font-family: "Poppins", sans-serif;
}
section {
  padding: 60px 0;
}
.container {
  max-width: 1340px;
  margin: 0px auto;
  padding-right: 15px;
  padding-left: 15px;
}
.container-fluid {
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.row > .col {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-1 {
  -ms-flex: 0 0 8.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  -ms-flex: 0 0 16.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -ms-flex: 0 0 33.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  -ms-flex: 0 0 41.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  -ms-flex: 0 0 58.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  -ms-flex: 0 0 66.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  -ms-flex: 0 0 75%;
  -webkit-box-flex: 0;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -ms-flex: 0 0 83.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  -ms-flex: 0 0 91.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
}

.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-left: auto;
}
.mx-auto {
  margin-right: auto;
  margin-left: auto;
}
.btn {
  font-style: normal;
  font-weight: 500;
  /* font-size: 20px; */
  font-size: 16px;
  /* line-height: 24px; */
  font-family: "Montserrat", sans-serif;
  /* padding: 14px 24px; */
  padding: 8px 20px;
  /* border-radius: 16px; */
  border-radius: 5px;
  border: 0;
  box-shadow: 0 0 0 1px transparent;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s;
}
.btn:hover {
  opacity: 0.7;
}
.btn.primary {
  background-color: var(--primary);
  color: var(--white);
}
.btn.outline {
  box-shadow: 0 0 0 2px var(--primary);
  color: var(--primary);
}
.btn.sm {
  padding: 12px 35px;
}
.switch {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.switch input {
  appearance: none;
  margin: 0 !important;
  width: 41px;
  height: 22px;
  background-color: var(--e6e7ee);
  border-radius: 100px;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
}
.switch span {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  background-color: var(--white);
  border-radius: 100px;
  top: 3px;
  left: 3px;
  transition: all 0.3s;
}
.switch.active span {
  transform: translateX(calc(100% + 3px));
}
.switch.active input {
  background-color: var(--primary);
}
section {
  padding: 35px 0;
}
.relative {
  position: relative;
}

/* HEADER */
.header {
  padding: 100px 0;
}
.header .row {
  align-items: center;
}
.header-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.header-info-wrapper .img {
  margin-right: 40px;
  width: 136px;
  height: 136px;
}
.header-info-wrapper .img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.header-info-wrapper .content h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 15px 0;
}
.header-info-wrapper .content a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--545454);
  display: inline-block;
  width: 100%;
  text-decoration: none;
}
.btn-language-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.btn-language-wrapper .header-language {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.btn-language-wrapper .header-language li {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--545454);
  opacity: 0.5;
  margin-left: 5px;
  cursor: pointer;
}
.btn-language-wrapper .header-language li.active {
  color: var(--0f0f0f);
  opacity: 1;
}
.quotes-table-title {
  margin-bottom: 30px;
}
.quotes-table-title h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
}
.common-box {
  padding: 30px;
  background: var(--white);
  box-shadow: var(--shadow);
  border-radius: 16px;
}
.quotes-table-inner {
  /* padding:30px 30px 80px 30px; */
  padding: 30px 30px 14px 30px;
  background: var(--white);
  box-shadow: var(--shadow);
  border-radius: 16px;
  overflow: auto;
  position: relative;
}
.quotes-table-inner .custom-pagination {
  position: fixed;
}
.quotes-table-inner table {
  /* width:1250px;*/
}

input:not([type="checkbox"], [type="radio"]),
select,
textarea {
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  border: 2px solid var(--e6e7ee);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 10px 14px;
  color: var(--545454);
}
section:last-child {
  padding-bottom: 130px;
}

input:not([type="checkbox"], [type="radio"]):focus,
select:focus,
textarea:focus,
input:not([type="checkbox"], [type="radio"]):hover,
select:hover,
textarea:hover {
  box-shadow: none;
  border-color: #465b95;
  border-width: 2px;
}

/* TABLE */
table .ant-table-thead th {
  color: var(--a9a9a9);
}
table .ant-table-thead th,
table .ant-table-tbody td {
  padding: 10px 5px;
  text-align: center;
  font-size: 16px;
}

/*  */
.listing-wrap ul li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.listing-wrap ul li > span {
  max-width: 49%;
  width: 100%;
  color: var(--545454);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  padding: 10px 0;
}
.listing-wrap ul li > span:nth-child(2) {
  color: var(--black);
  text-align: right;
}
.table-content-quote-doc {
  padding-top: 50px;
}
.table-content-quote-doc h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--545454);
  margin-bottom: 18px;
}
.table-content-quote-doc ul {
  display: flex;
  flex-wrap: wrap;
}
.table-content-quote-doc ul li {
  margin-right: 20px;
  margin-bottom: 20px;
}
.table-content-quote-doc ul li img {
  max-width: 60px;
  height: auto;
  object-fit: contain;
}
.table-content-wrapper button:not([role="switch"]),
.content-view-modal-wrapper button:not([role="switch"]) {
  margin-bottom: 10px;
  margin-top: 50px;
  border-radius: 16px;
  min-width: 120px;
  height: unset;
  padding: 11px 10px;
}
img {
  max-width: 100%;
}
.documet-list .doc-item {
  padding-left: 30px;
  position: relative;
  padding-right: 12px;
  display: inline-block;
  width: auto;
}
.documet-list .doc-item .file-icon {
  position: absolute;
  left: 0;
}
.documet-list .doc-item .close-icon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.input-file-upload {
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
}
.input-file-upload input {
  position: absolute;
  margin: 0;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 9;
}
.input-file-upload span {
  font-weight: 500;
  font-size: 14px;
  opacity: 0.5;
  pointer-events: none;
}
.documet-list .doc-item span {
  font-size: 12px;
}
.doc-file-upload-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content-view-modal-sec .sec-bg-img {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 680px;
  pointer-events: none;
}
.content-view-modal-sec .full-sec-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -9;
}

.create-data-form-sec .btn-language-wrapper {
  justify-content: flex-end;
  padding-bottom: 50px;
}
.form-title {
  margin-bottom: 60px;
  width: 100%;
}
.form-title h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: var(--black);
  font-family: "Montserrat", sans-serif;
}
.input-item > label {
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: var(--545454);
}

.create-data-form-filed form {
  display: flex;
  flex-wrap: wrap;
}
.create-data-form .input-item {
  margin-bottom: 30px;
}
.create-data-form .small {
  max-width: 130px;
  margin-right: 6.4%;
}
.create-data-form .deal {
  max-width: 310px;
}
.create-data-form .w-full {
  width: 100%;
}

.create-data-form .uploaded-document ul {
  display: inline-flex;
  flex-wrap: wrap;
}
.create-data-form .uploaded-document li > img {
  max-width: 60px;
  height: auto;
  object-fit: contain;
}
.create-data-form .uploaded-document li {
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
}
.create-data-form .icon-close {
  position: absolute;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 3px;
  right: 3px;
  cursor: pointer;
}
.create-data-form {
  margin-bottom: 10px;
  margin-top: 30px;
}
.create-data-form .uploaded-document .add-btn {
  margin-right: 5px;
  padding: 7px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  vertical-align: top;
  display: inline-block;
}

.create-data-form-sec-2 .sec-bg-img {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 680px;
  pointer-events: none;
}
.request-data-sec-2 .sec-bg-img {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
}
.request-data-sec-2 .bottom-image {
  top: unset;
  left: unset;
  right: 0;
  bottom: 0;
}

.table-content-inner-2 .small input {
  max-width: 130px;
}
.table-content-inner-2 form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.table-content-inner-2 form .input-item {
  width: 49%;
  margin-bottom: 30px;
}
.table-content-inner-2 form .input-item:nth-child(even) {
  padding-left: 30px;
}
.table-content-inner-2 form .input-item.w-full {
  width: 100%;
}
.table-content-inner-2 .doc-list {
  display: inline-flex;
  flex-wrap: wrap;
}
.table-content-inner-2 .doc-list li {
  margin-right: 20px;
  margin-bottom: 20px;
}
.table-content-inner-2 .doc-list li > img {
  max-width: 60px;
  height: auto;
  object-fit: contain;
}
.table-content-inner-2 .add-btn {
  margin-right: 5px;
  padding: 7px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  vertical-align: top;
  display: inline-block;
}

/* custom-tabs */
.custom-tabs .ant-tabs-nav-list {
  display: flex;
  /* flex-wrap: wrap; */
  /* border-bottom: 1px solid #eeeeee; */
  border-bottom: none;
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 10px;
}
.custom-tabs .ant-tabs-tab {
  padding: 8px 20px !important;
  color: #a4a4a4;
  cursor: pointer;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
.custom-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #465b95;
  color: #fff;
  border-radius: 5px;
}
.custom-tabs .ant-tabs-tab:hover {
  color: #465b95;
}
.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}
.custom-tabs .ant-tabs-tab div {
  white-space: pre;
}

.custom-pagination ul {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
.custom-pagination ul li a {
  display: inline-flex;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  background-color: rgba(70, 91, 149, 0.2);
  font-size: 16px;
  color: #465b95;
}
.custom-pagination ul li.selected a {
  background-color: #465b95;
  color: #fff;
}
.view-Btn {
  background-color: #465b95;
  padding: 8px 20px;
  border-radius: 5px;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  font-size: 16px;
}

.cs-form-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9;
}
.cs-form-modal .row > .col {
  position: relative;
}
.cs-form-modal .container .row {
  justify-content: center;
  padding-top: 40px;
}
.cs-form-modal .container .row .form-group {
  margin-bottom: 20px;
}
.cs-form-modal .container .row .form-group label {
  margin-bottom: 10px;
  display: block;
}
.cs-form-modal .container .user-modal-filed-wrapper > .row {
  padding-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.cs-form-modal .content-view-modal-wrapper button {
  margin-top: 20px;
}
.cs-form-modal .closeBtn {
  width: 24px;
  height: 24px;
  border: 1px solid grey;
  position: relative;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 26px;
  cursor: pointer;
}
.cs-form-modal .closeBtn:before,
.cs-form-modal .closeBtn:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 2px;
  background-color: grey;
}
.cs-form-modal .closeBtn:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.cs-form-modal .closeBtn:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.cs-form-modal .common-box {
  padding-top: 50px;
}
.cs-form-modal.loginModal .privacy-text {
  color: #8794ba;
  margin-top: 12px;
}
.cs-form-modal.loginModal .privacy-text button {
  background-color: transparent;
  border: none;
  line-height: normal;
  margin: 0px;
  color: #000000d9;
}
/* custom-tabs-end */

/* loginModal */
.loginModal .common-box {
  padding: 40px 20px;
  width: 585px;
  max-width: 100%;
}
.cs-form-modal.loginModal .container .row {
  margin-left: 0px;
  margin-right: 0px;
}
.loginModal.cs-form-modal .login-sec > .container > .row {
  padding-top: 0px;
}
.loginModal .content-view-modal-wrapper .col-6 {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0px;
}
.loginModal .formTitle {
  text-align: center;
  font-size: 21px;
  font-weight: 500;
  color: var(--primary);
  margin-bottom: 10px;
}
.loginModal .loginBG-wrapper {
  position: relative;
  max-width: 100%;
}
.loginModal .customRadioWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
}
.loginModal .customRadioWrapper .customRadioBtn {
  position: relative;
  margin-right: 22px;
}
.loginModal .customRadioWrapper input {
  position: absolute;
  top: 4px;
  left: 2px;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  width: 14px;
  height: 14px;
}
.loginModal .customRadioWrapper .customRadioBtn span {
  position: relative;
  padding-left: 22px;
}
.loginModal .customRadioWrapper .customRadioBtn span:before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #1890ff;
  border-radius: 50%;
}
.loginModal .customRadioWrapper .customRadioBtn span:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  display: none;
}
.loginModal .customRadioWrapper .customRadioBtn input:checked + span:after {
  display: block;
}
.loginModal input {
  border: 1px solid #465b95;
  border-radius: 5px;
  font-size: 14px;
  padding: 7px 10px 7px 28px !important;
  color: #465b95;
  font-weight: 400;
  font-size: 14px;
}
.loginModal .PhoneInput input {
  padding: 7px 10px 7px 5px !important;
}
.loginModal input::placeholder {
  color: #465b95;
  opacity: 1;
}
.loginModal .input-item,
.loginModal .PhoneInput {
  margin-bottom: 15px;
}
.loginModal .input-item {
  position: relative;
}
.loginModal .input-item .ant-btn {
  margin: 0px;
  padding: 0px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  top: 8px;
  right: 7px;
  border: none;
  background-color: transparent;
}
.loginModal .input-item .ant-btn:hover {
  color: #000 !important;
}
.loginModal .input-item .ant-btn .ant-space {
  position: static;
}
.loginModal .input-item .ant-space {
  position: absolute;
  top: 6px;
  left: 10px;
  opacity: 0.3;
}
.loginModal .PhoneInput {
  border: 1px solid #465b95;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.loginModal .PhoneInput select {
  font-size: 14px;
  font-weight: 400;
}
.loginModal .PhoneInput input {
  border: none;
}
.loginModal .customCheckBox {
  display: inline-block;
  position: relative;
}
.loginModal .customCheckBox input {
  position: absolute;
  opacity: 0;
  z-index: 5;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0px;
}
.loginModal .customCheckBox label {
  position: relative;
  padding-left: 24px;
}
.loginModal .customCheckBox label:before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  transition: 0.5s;
}
.loginModal .customCheckBox label::after {
  content: "";
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transition: 0.5s;
  width: 6px;
  height: 10px;
  transform: rotate(45deg);
  top: 3px;
  left: 5px;
  display: none;
}
.loginModal .customCheckBox input:hover + label:before,
.loginModal .customCheckBox label:hover:before {
  border: 1px solid #465b95;
}
.loginModal .customCheckBox input:checked + label:before {
  background-color: #465b95;
  border: 1px solid #465b95;
}
.loginModal .customCheckBox input:checked + label:after {
  display: block;
}
.loginModal .formFooter {
  text-align: center;
}
.loginModal .formFooter .ant-btn {
  background-color: #465b95;
  padding: 8px 20px;
  border-radius: 5px;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;
  height: auto;
  width: 200px;
  text-align: center;
  margin-bottom: 20px;
}
.loginModal .formFooter .contant button {
  background-color: transparent;
  border: none;
  line-height: normal;
  margin: 0px;
}
.loginModal .formFooter .contant p {
  margin: 0px;
  line-height: normal;
  color: #8794ba;
  margin-top: 8px;
  margin-bottom: 8px;
}
.loginModal .formFooter .contant p button {
  color: #000000d9;
}
.loginModal.cs-form-modal .closeBtn {
  background-color: transparent;
  right: 11px;
}
/* loginModal-end */

.ant-tabs .ant-tabs-ink-bar {
  background-color: var(--primary);
}

/* 09-05-2022 X */
table .ant-table-thead th,
table .ant-table-tbody td {
  background-color: transparent !important;
  color: var(--a9a9a9) !important;
  font-size: 16px;
  line-height: 18px;
  padding: 10px 5px !important;
  border: 0 !important;
}
table .ant-table-tbody td {
  color: var(--black) !important;
  font-weight: 500;
}

table .ant-table-thead tr:hover th,
table .ant-table-tbody tr:hover td {
  background-color: transparent !important;
}

table .ant-table-thead th::before,
table .ant-table-tbody td::before {
  opacity: 0 !important;
}

table .view-Btn {
  padding: 5px 11px;
  font-size: 14px;
}

table .ant-table-thead th,
table .ant-table-tbody td {
  text-align: left;
}

.ant-modal-content .ant-modal-body {
  padding-top: 60px;
}

.ant-modal-content .ant-modal-close span {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.ant-modal-content .ant-modal-close {
  top: 10px;
  right: 10px;
}
.ant-modal-body .ant-row .ant-form-item {
  width: 100%;
}
/* .ant-modal-body .ant-form-item .ant-form-item-label {width: 27%;flex-grow: unset;margin-right: auto;text-align: left;} */
.ant-modal-body .ant-form-item .ant-form-item-label {
  width: 100%;
  flex-grow: unset;
  margin-right: auto;
  text-align: left;
}
/* .ant-modal-body .ant-form-item .ant-form-item-control {
  flex: unset;
  width: 70%;
} */
.ant-modal-body .ant-form-item .ant-form-item-control {
  flex: unset;
  width: 100%;
}
.ant-modal-body .ant-form-item:last-child .ant-form-item-control {
  width: 100%;
  margin-left: 0;
  max-width: 100%;
  text-align: center;
}
.ant-form-item-label > label {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}
.ant-form-item-control-input-content .ant-btn {
  background-color: var(--primary);
  border-color: var(--primary);
  padding: 8px 30px;
  height: unset;
  width: auto;
  border-radius: 5px;
}
.ant-modal-footer {
  display: none;
}
.ant-modal-body .ant-input-number,
.ant-modal-body .ant-picker-input {
  border: 0;
  width: 100%;
}
.ant-modal-body .ant-input-number input,
.ant-modal-body .ant-picker-input {
  font-size: 16px;
  line-height: 20px;
  min-height: 45px;
}
.ant-modal-body
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: unset;
  padding: 6px 10px;
  min-height: 45px;
  border: 2px solid var(--e6e7ee);
  border-radius: 15px;
}
.ant-form-item .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-form-item .ant-select:not(.ant-select-disabled) .ant-select-selector:focus,
div .ant-picker:hover,
div .ant-picker-focused {
  border-color: var(--primary);
  border-width: 2px;
  box-shadow: none;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: var(--primary) !important;
  border-width: 2px !important;
  box-shadow: none !important;
}
.ant-input-number:focus,
.ant-input-number-focused {
  box-shadow: none !important;
}
.ant-form-item-control-input-content .ant-picker {
  width: 100%;
  border-radius: 15px;
  border: 2px solid var(--e6e7ee);
}
.ant-form-item-control-input-content .ant-picker-input {
  min-height: unset;
  padding: 6px 0;
}
.ant-input-number-handler-wrap {
  background-color: unset !important;
  transform: scale(0.9);
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: var(--primary);
}

div .ant-btn:hover,
div .ant-btn:focus,
div .ant-pagination-item:hover a,
div .ant-pagination-item:hover,
div .ant-pagination-item-active a,
div .ant-pagination-item-active:hover a,
div .ant-pagination-item-active,
div .ant-pagination-item-active:hover {
  border-color: var(--primary);
  color: var(--primary);
}

div
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
div .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--primary);
  box-shadow: none;
}

.title-btn-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.title-btn-row button:not([role="switch"]) {
  border-radius: 16px;
  min-width: 120px;
  height: unset;
  padding: 9px 10px;
}
div .ant-switch-checked {
  background-color: #465b95;
}

.ant-form .ant-row.ant-form-item {
  margin-top: 20px;
}

/* ************* RESPONSIVE ***************** */
@media screen and (max-width: 1370px) {
  .header {
    padding: 50px 0;
  }
  .header-info-wrapper .content h3 {
    margin: 0 0 15px 0;
  }
  .header-info-wrapper .img {
    width: 116px;
    height: 116px;
  }
  .container {
    max-width: 1240px;
  }
  table td,
  table th {
    font-size: 15px;
  }
  .quotes-table-title {
    margin-bottom: 20px;
  }
  section {
    padding: 25px 0;
  }
  section:last-child {
    padding-bottom: 90px;
  }
  .content-view-modal-sec .sec-bg-img {
    height: 550px;
  }
  .btn {
    line-height: normal;
    font-size: 18px;
    padding: 13px 20px;
    border-radius: 10px;
  }
  .btn.sm {
    padding: 10px 25px;
    font-size: 15px;
  }

  .create-data-form .small {
    margin-right: 2.7%;
  }
  .form-title {
    margin-bottom: 40px;
  }

  .create-data-form-sec-2 .sec-bg-img {
    max-width: 570px;
  }
}

@media screen and (max-width: 1170px) {
  .header {
    padding: 30px 0;
  }
  .header-info-wrapper .img {
    margin-right: 20px;
    width: 100px;
    height: 100px;
  }
  .header-info-wrapper .content h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .header-info-wrapper .content a {
    font-size: 15px;
  }
  .quotes-table-title h4 {
    font-size: 18px;
    line-height: normal;
  }

  .container {
    max-width: 940px;
  }
  .quotes-table-inner table {
    width: 1250px;
  }
  .content-view-modal-sec .sec-bg-img {
    height: 410px;
  }

  .create-data-form .input-item {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .create-data-form .small {
    max-width: 48%;
    margin-right: 2%;
  }
  .create-data-form-sec-2 .sec-bg-img {
    max-width: 420px;
  }
}
@media screen and (max-width: 1199px) {
  .quotes-table-sec {
    padding-top: 15px;
  }
  .custom-tabs {
    margin-top: 30px;
  }
  .custom-tabs .ant-tabs-nav {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 992px) {
  section {
    padding: 20px 0;
  }
  .content-view-modal-sec > img {
    display: none;
  }
  .table-content-sec .col-6,
  .content-view-modal-sec .col-6 {
    max-width: 70%;
    flex: 0 0 70%;
    margin: 0 auto;
  }

  .create-data-form-sec .img-col {
    display: none;
  }
  .create-data-form-sec .col-6 {
    max-width: 70%;
    flex: 0 0 70%;
    margin: 0 auto;
  }

  section:last-child {
    padding-bottom: 80px;
  }
  .request-data-sec-2 .sec-bg-img,
  .create-data-form-sec-2 .sec-bg-img {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .header-info-wrapper .img {
    margin-right: 10px;
  }
  .header-info-wrapper .content {
    width: calc(100% - 115px);
  }
  .header-info-wrapper .content h3 {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 767px) {
  .header-info-wrapper .img {
    width: 60px;
    height: 60px;
  }
  .header-info-wrapper .content {
    width: calc(100% - 70px);
    line-height: normal;
  }
  .header-info-wrapper .content h3 {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 5px;
  }
  .header-info-wrapper .content a {
    font-size: 12px;
    line-height: normal;
  }
  .header .btn-language-wrapper .btn {
    font-size: 12px;
    padding: 8px 15px;
  }
  .btn-language-wrapper .header-language li {
    font-size: 12px;
  }
}
@media screen and (max-width: 575px) {
  .header .btn-language-wrapper {
    justify-content: space-between;
    margin-top: 20px;
  }
  .header .header-auth-btn-wrap {
    margin: 0px !important;
  }
  .header {
    padding: 20px 0px;
  }
  .custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 12px;
  }
  .custom-tabs .ant-tabs-tab div {
    font-size: 12px;
  }
  .custom-tabs .ant-tabs-tab {
    padding: 6px 15px !important;
  }
  .custom-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 5px;
  }
  .view-Btn {
    font-size: 12px;
  }
  .header .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .loginModal .common-box {
    padding: 40px 5px;
  }
}
@media screen and (max-width: 425px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .table-content-sec .col-6,
  .content-view-modal-sec .col-6 {
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0 auto;
  }
  .quotes-table-inner,
  .common-box {
    padding: 16px;
    border-radius: 10px;
  }
  .table-content-wrapper button,
  .content-view-modal-wrapper button {
    margin-top: 20px;
  }

  .listing-wrap ul {
    display: flex;
    flex-wrap: wrap;
  }
  .listing-wrap ul li {
    margin-bottom: 6px;
    width: 100%;
  }
  .listing-wrap ul li > span:nth-child(2) {
    text-align: left;
  }
  .listing-wrap ul li > span:nth-child(1) {
    font-weight: 400;
  }
  .listing-wrap ul li:nth-child(1),
  .listing-wrap ul li:nth-child(2),
  .listing-wrap ul li:nth-child(6),
  .listing-wrap ul li:nth-child(7) {
    width: 50%;
  }
  .listing-wrap ul li > span {
    display: block;
    max-width: 100%;
    padding: 6px 0;
  }

  .table-content-quote-doc {
    padding-top: 30px;
  }

  .content-view-modal-sec .listing-wrap ul li {
    width: 100%;
  }
  .content-view-modal-sec .listing-wrap ul li > span {
    font-weight: 700;
  }

  .create-data-form-sec .col-6 {
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0 auto;
  }
  .create-data-form .small {
    margin-right: 0;
    max-width: 100%;
  }
  .input-item > label {
    font-weight: 400;
  }
  .create-data-form .uploaded-document li {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .create-data-form-sec .btn-language-wrapper {
    display: none;
  }
  .form-title h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .form-title {
    margin-bottom: 20px;
  }
  .create-data-form {
    margin-top: 0;
  }

  .table-content-inner-2 form .input-item {
    width: 100%;
    margin-bottom: 16px;
  }
  .table-content-inner-2 form .input-item:nth-child(even) {
    padding-left: 0;
  }
}
