@import "~antd/dist/antd.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div[dir="rtl"] .header-info-wrapper .content {
  margin-right: 15px;
}
div[dir="rtl"] .header-info-wrapper .img {
  margin-right: 0;
}
div[dir="rtl"] .ant-table-container table th,
div[dir="rtl"] .ant-table-container table td {
  text-align: right;
}
div[dir="rtl"] .ant-modal-body .ant-form-item .ant-form-item-label {
  text-align: right;
}

.ant-modal.rtl .ant-modal-body li {
  direction: rtl;
}
.ant-modal.rtl .ant-modal-body li span:first-child {
  text-align: right;
}
.ant-modal.rtl .ant-modal-body li span:last-child {
  text-align: left;
}

.ant-modal.rtl .ant-modal-body .ant-form-item .ant-form-item-label,
.ant-modal.rtl .ant-modal-body .ant-input-number input,
.ant-modal.rtl .ant-modal-body .ant-form-item-control-input input,
.ant-modal.rtl .ant-modal-body .ant-form-item-control-input-content {
  text-align: right;
}

.ant-modal.rtl .ant-modal-body .ant-collapse-header {
  justify-content: end;
}

.ant-modal.rtl .ant-modal-content .ant-modal-close {
  left: 10px;
}
div[dir="rtl"] .ant-pagination-next {
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  div[dir="rtl"] .header-info-wrapper .content {
    margin-right: 10px;
  }

  .ant-modal.rtl .ant-modal-body li span:last-child {
    text-align: right;
  }

  .ant-table-content table .ant-table-thead th,
  .ant-table-content table .ant-table-tbody td,
  .ant-modal-body .listing-wrap ul li > span {
    font-size: 12px;
    line-height: 18px;
  }
}
